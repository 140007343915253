import React from 'react';
import {StyleSheet, View, Text, TouchableOpacity} from "react-native";

function TaskItem(props:any) {
	return (
		<TouchableOpacity activeOpacity={0.3} onPress={props.onDelete}>
			<View style={styles.listItem}>
				<Text>{props.value}</Text>
			</View>
		</TouchableOpacity>
	);
}

const styles = StyleSheet.create({
	listItem: {
		borderColor: "black",
		borderWidth: 1,
		backgroundColor: "#f9f9f9",
		marginTop: 5,
		paddingVertical: 5,
		paddingHorizontal: 10,
	}
});

export default TaskItem;