import {StatusBar} from 'expo-status-bar';
import React, { useState } from 'react';
import {Button, ScrollView, StyleSheet, Text, TextInput, View, FlatList} from 'react-native';
import TaskItem from "./components/TaskItem/TaskItem";
import TaskAddingForm from "./components/TaskAddingForm/TaskAddingForm";

export default function App() {
	const [tasksList, setTasksList]:any[] = useState(
		// []
		["task 1","task 2","task 3","task 4","task 5","task 6","task 7",
			"task x1","task x2","task x3","task x4","task x5","task x6","task x7",
			"task y1","task y2","task y3","task y4","task y5","task y6","task y7",
			"task z1","task z2","task z3","task z4","task z5","task z6","task z7",].map((task:string) => {
			return {
				id: Math.random().toString(),
				value: task,
			};
		})
	);
	const [isAddModalOpened, setAddModalOpened]:any[] = useState(false);

	const addTask = (enteredTask:string) => {
		setTasksList([{
			id: Math.random().toString(),
			value: enteredTask,
		}, ...tasksList]);
	};

	const removeTask = (taskId:string) => {
		setTasksList(tasksList.filter((task:any) => task.id !== taskId));
	};

	return (
		<View style={styles.screen}>
			<View style={styles.addTaskArea}>
				<Button title={"Add new task"}
					    onPress={() => setAddModalOpened(true)} />
				<TaskAddingForm isVisible={isAddModalOpened}
				                onAddTask={addTask}
				                onClose={() => setAddModalOpened(false)} />
			</View>

			<FlatList style={styles.list}
			          data={tasksList}
			          // keyExtractor={(item, index) => item.id}
			          renderItem={(itemData:any) => (
			            <TaskItem value={itemData.item.value}
			                      onDelete={() => removeTask(itemData.item.id)}/>
			          )}/>

			<StatusBar style="auto"/>
		</View>
	);
}

const styles = StyleSheet.create({
	screen: {
		padding: 30,
		paddingTop: 50,
		flex: 1,
		// backgroundColor: "#ccc",
	},
	addTaskArea: {
		// backgroundColor: "#55c",
	},
	list: {
		flexDirection: "column",
		flex: 1,
		// backgroundColor: "#cc4",
		// height: "wrap_content",
		// alignSelf:'baseline',
	},
});
