import {Button, Modal, StyleSheet, TextInput, Touchable, TouchableOpacity, View} from "react-native";
import React, {useState} from "react";

function TaskAddingForm(props:any) {
	const [enteredTask, setEnteredTask] = useState("");
	const addTaskHandler = () => {
		props.onAddTask(enteredTask);
		props.onClose();
		setEnteredTask("");
	};

	return (
		<Modal visible={props.isVisible} animationType={"slide"}>
			<View style={styles.inputContainer}>
				<TextInput placeholder={"Enter task text ..."}
				           style={styles.input}
				           value={enteredTask}
				           onChangeText={(text) => setEnteredTask(text)}/>
				<View style={styles.buttonsContainer}>
					<View style={styles.button}>
						<Button title={"CANCEL"} color={"gray"} onPress={props.onClose}/>
					</View>
					<View style={styles.button}>
						<Button title={"ADD"} onPress={addTaskHandler}/>
					</View>
				</View>
			</View>
		</Modal>
	);
}

const styles = StyleSheet.create({
	inputContainer: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
		// backgroundColor: "#ccc"
	},
	input: {
		borderBottomColor: "#000",
		borderBottomWidth: 1,
		marginBottom: 5,
		padding: 10,
		width: "80%",
	},
	buttonsContainer: {
		flexDirection: "row",
		// justifyContent: "space-evenly",
		justifyContent: "center",
		// marginHorizontal: 10,
		alignItems: "center",
		width: "60%",
		// backgroundColor: "#ccc"
	},
	button: {
		marginHorizontal: 5,
		width: "40%",

	}
});

export default TaskAddingForm;